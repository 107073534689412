import { Exercise, ExerciseExecution } from "@/utils/entities";
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
} from "@/components/ui/sheet";
import { Button } from "@/components/ui/button";
import { Separator } from "@/components/ui/separator";
import { useForm } from "react-hook-form";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { useToast } from "@/components/ui/use-toast";
import { SheetService } from "@/services/api/sheet";
import { useParams } from "react-router-dom";
import { getToastErrorContent } from "@/utils/functions/api-error";
import Spinner from "@/components/ui/spinner";

type SheetDialogEditExerciseProps = {
  isOpened: boolean;
  exercise: Exercise;
  onCancel?: () => void;
  onChange?: () => void;
};

type FormSchemaType = ExerciseExecution;

export default function SheetDialogEditExercise({
  isOpened,
  exercise,
  onCancel,
  onChange,
}: SheetDialogEditExerciseProps) {
  const { toast } = useToast();
  const { sheetId } = useParams();
  const {
    reset,
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormSchemaType>({
    values: exercise?.execution,
  });

  const handleCancel = () => {
    reset();

    onCancel?.();
  };

  const onSubmit = async (execution: FormSchemaType) => {
    try {
      if (!sheetId) {
        reset();

        onChange?.();

        return;
      }

      await SheetService.updateExecution(sheetId, execution);

      toast({
        variant: "success",
        description: "Informações atualizada com sucesso",
      });

      reset(execution);

      onChange?.();
    } catch (error) {
      console.log(error);

      toast(getToastErrorContent(error));
    }
  };

  if (!isOpened || !exercise) {
    return null;
  }

  return (
    <Sheet open={isOpened}>
      <SheetContent
        side="right"
        className="bg-white min-h-[80%] flex flex-col gap-0 p-0"
        onClose={handleCancel}
      >
        <SheetHeader className="p-4">
          <SheetTitle>{exercise?.name}</SheetTitle>
          <SheetDescription>
            atualize as informações complementares da exercucão do exercício
          </SheetDescription>
        </SheetHeader>

        <Separator />

        <div className="flex flex-1 flex-col col-span-1 overflow-scroll">
          <div className="flex flex-col p-4 gap-5">
            <div className="flex flex-col gap-2">
              <Label htmlFor="sets">Séries</Label>
              <Input id="sets" {...register("sets")} />
            </div>
            <div className="flex flex-col gap-2">
              <Label htmlFor="repetitions">Repetições</Label>
              <Input id="repetitions" {...register("repetitions")} />
            </div>
            <div className="flex flex-col gap-2">
              <Label htmlFor="load">Carga</Label>
              <Input id="load" {...register("load")} />
            </div>
            <div className="flex flex-col gap-2">
              <Label htmlFor="cadence">Cadência</Label>
              <Input id="cadence" {...register("cadence")} />
            </div>
            <div className="flex flex-col gap-2">
              <Label htmlFor="interval">Intervalo</Label>
              <Input id="interval" {...register("interval")} />
            </div>
            <div className="flex flex-col gap-2">
              <Label htmlFor="observation">Observação</Label>
              <Textarea id="observation" {...register("observation")} />
            </div>
          </div>
        </div>

        <SheetFooter className="border-t py-5 px-4">
          <SheetClose asChild>
            <Button
              disabled={isSubmitting}
              variant="outline"
              onClick={handleCancel}
            >
              Cancelar
            </Button>
          </SheetClose>
          <SheetClose asChild>
            <Button disabled={isSubmitting} onClick={handleSubmit(onSubmit)}>
              {isSubmitting ? (
                <Spinner className="h-[14px] w-[14px] mr-2" />
              ) : null}
              Salvar
            </Button>
          </SheetClose>
        </SheetFooter>
      </SheetContent>
    </Sheet>
  );
}
