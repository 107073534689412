import { GenericStatus, Exercise } from "@/utils/entities";
import api from "./config";
import { getStatusText } from "@/utils/functions/parse-data";
import { getDateFormatText } from "@/utils/functions/date-transform";

export type ExerciseCreateInput = {
  id?: string;
  name: string;
  status: string;
  video_url: string;
  exerciseTypeId: string;
};

export type ExerciseUpdateInput = {
  id: string;
  name: string;
  status: string;
  video_url?: string;
  exerciseTypeId: string;
};

type ExerciseGetInput = {
  page: number;
  take?: number;
  type?: string | undefined;
  name?: string | undefined;
  category?: string | undefined;
};

type ListPaginationOutput = {
  exercises: Exercise[];
  pagination: {
    count: number;
    currentPage: number;
    nextPage: number | null;
    prevPage: number | null;
    lastPage: number;
  };
};

function parseItem(item: Exercise) {
  return {
    ...item,
    isActive: item.status === GenericStatus.ACTIVE,
    statusText: getStatusText(item.status as GenericStatus),
    createdAtText: getDateFormatText(item.createdAt),
    updatedAtText: getDateFormatText(item.updatedAt),
  } as Exercise;
}

export async function getAllExercises(
  params: ExerciseGetInput
): Promise<ListPaginationOutput> {
  const data = (await api.get(`/exercises`, { params })).data;

  return {
    pagination: data.pagination,
    exercises: data.exercises.map((item: Exercise) => parseItem(item)),
  };
}

async function createExercises(data: ExerciseCreateInput) {
  return (await api.post("/exercises", data)).data;
}

async function updateExercises(data: ExerciseUpdateInput) {
  return (await api.put(`/exercises/${data.id}`, data)).data;
}

async function deleteExercise(data: Exercise) {
  return (await api.delete(`/exercises/${data.id}`)).data;
}

export const ExerciseService = {
  delete: deleteExercise,
  getAll: getAllExercises,
  create: createExercises,
  update: updateExercises,
};
