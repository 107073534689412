import { Button } from "@/components/ui/button";
import { cn } from "@/utils";
import { Exercise } from "@/utils/entities";
import { EditIcon, TrashIcon } from "lucide-react";
import size from "../../../assets/600x900.svg";
import CoverVideo from "../../../assets/cover.webp";

interface ExerciseProps extends React.HTMLAttributes<HTMLDivElement> {
  exercise: Exercise;
  width?: number;
  height?: number;
  onDelete?: () => void;
  onEdit?: () => void;
  onVideo?: () => void;
}

export default function ExerciseItem({
  exercise,
  width,
  height,
  onEdit,
  onVideo,
  onDelete,
  className,
  ...props
}: ExerciseProps) {
  return (
    <div className={cn("space-y-4 pb-3", className)} {...props}>
      {exercise?.video_url ? (
        <video
          controls
          poster={CoverVideo}
          src={exercise.video_url}
          className="h-auto w-auto object-contain portrait aspect-[3/4]"
        />
      ) : (
        <img
          src={size}
          width={width}
          height={height}
          onClick={onVideo}
          alt={exercise?.name}
          className="h-auto w-auto object-cover portrait aspect-[3/4] transition-all hover:scale-105 rounded-t-md hover:rounded-md"
        />
      )}

      <div className="flex-1 mx-4">
        <h3 className="text-md font-semibold text-zinc-300">
          {exercise?.name}
        </h3>
        <p className="text-sm mt-2 mb-2 text-zinc-400">
          {exercise?.type?.name}
        </p>
      </div>

      <div className="flex flex-row gap-4 justify-end mx-4">
        <p className="text-xs text-zinc-600 self-end">
          Atualizado em {exercise?.updatedAtText}
        </p>
        <Button
          size="icon"
          onClick={onDelete}
          variant="secondary"
          className="rounded-full bg-red-900"
        >
          <TrashIcon className="w-4 h-4 text-white" />
        </Button>
        <Button
          size="icon"
          onClick={onEdit}
          variant="secondary"
          className="rounded-full"
        >
          <EditIcon className="w-4 h-4" />
        </Button>
      </div>
    </div>
  );
}
